<template>
  <div class="topbar">
    <Logo />
    <MenuItem label="자료변환" :route="{ name: 'DocumentConvert' }" />
    <MenuItem label="유라 수출입 자료조회" :route="{ name: 'YuraImportDocument', query: {deleted:'false'}}" v-if="user"/>
    <MenuItem label="통관실적" :route="{ name: 'PerformanceReport', query: {deleted:'false'}}" v-if="user"/>
    <div class="spacer"></div>
    <LoginForm />
  </div>
</template>

<script>
import Logo from '@/components/Logo'

import MenuItem from './MenuItem'
import LoginForm from './LoginForm'

export default {
  name: 'TopBar',
  components: {
    Logo,
    LoginForm,
    MenuItem,
  },
}
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: start;
  align-items: center;

  padding: 12px 20px;
  height: 60px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  & > img {
    height: 40px;
    margin-right: 120px;
  }

  & > .spacer {
    flex: 1;
  }
}
</style>
