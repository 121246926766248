<template>
  <Container>
    <div class="page">
      <form action="/api/performance_report" method="post" enctype="multipart/form-data">
        <h1>통관실적 자료변환</h1>
        <label>
          자료 업로드 : 
          <input
          type="file"
          name="performance_report"
          accept=".xlsx, .xls" />
        </label>
        <div class="buttons">
          <input type="submit"/>
        </div>
      </form>
    </div>
  </Container>
</template>

<script>
import Frame from '@/views/Frame'

export default {
  name: 'PerformanceReport',
  components: {
    Container: Frame,
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 0 40px;
}
</style>